import * as client_hooks from '../../../src/hooks.client.js';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26')
];

export const server_loads = [];

export const dictionary = {
		"/": [8],
		"/about": [9,[2]],
		"/browse": [10],
		"/calculator": [11],
		"/forms/confirmation": [12,[3,4]],
		"/forms/quote-mobile": [14,[3]],
		"/forms/quote": [13,[3]],
		"/models/axe": [16],
		"/models/eden": [17],
		"/models/ilo": [18],
		"/models/[slug]": [15],
		"/pages/ilo-stcat": [19],
		"/privacy": [20,[5]],
		"/process": [21],
		"/rbc": [22],
		"/resources": [23,[6]],
		"/resources/[slug]": [24,[6]],
		"/result": [25],
		"/terms": [26,[7]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';